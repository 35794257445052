import moment from "moment";
import {
    DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT,
    FX_ORDER_CONFIRM_STATUS_CODE,
    FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE,
    FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE,
    FX_TRADE_CURRENCY_TYPE,
} from "../../../../../constants/fx.constant";
import {
    ConfirmFXOrderAPIResponseType,
    CreateFXOrderAPIResponseType,
    FXOrderFormRequiredType,
    FXOrderRSAType,
    FXOrderTransactionAcknowledgementInsufficientFundType,
    FXOrderTransactionAcknowledgementType,
    FXType,
    S2UInitAPIResponseType,
} from "../../../../../types/fx.type";
import getRateByDealt from "../../../getRateByDealt";
import formatFXRawList from "../formatFXRawList";
import {getConversionFee} from "../../../order";
import {getIntlObject} from "../../../../../utils/intl";
import {formatNumber} from "../../../../../utils/common.utils";
import {renderRate} from "../../../../../components/order/CalculationCard";
import {store} from "../../../../../store/store";
import {useAppSelector} from "../../../../../utils/store.utils";
import {selectFXOrderForm} from "../../../../../slice/fx";
import {getDpUqBySymbol} from "../../../../../components/fx/service/getDpUqBySymbol";

/**
 * Format confirm FX order API response.
 * @param {ConfirmFXOrderAPIResponseType} response - The FX confirm order API response
 * @param {FXOrderFormRequiredType} fxOrderForm - The FX order form (All fields required)
 * @return {FXOrderTransactionAcknowledgementType | FXOrderRSAType} The formatted FX order transaction acknowledgement object.
 */


const formatConfirmFXOrder = (
    response: ConfirmFXOrderAPIResponseType | S2UInitAPIResponseType | CreateFXOrderAPIResponseType,
    fxOrderForm: FXOrderFormRequiredType
): FXOrderTransactionAcknowledgementType | FXOrderRSAType | FXOrderTransactionAcknowledgementInsufficientFundType => {
    const intl = getIntlObject();
    if (response.responseCode === FX_ORDER_CONFIRM_STATUS_CODE.SUCCESS
        || response.responseCode === FX_ORDER_CONFIRM_STATUS_CODE.SUCCESS_BUT_ET_GATEWAY_FAIL)
    {
        // Success case
        const fx: FXType = {
            //@ts-ignore
            ...formatFXRawList([response.data.symbol])[0],
            //@ts-ignore
            bid: isNaN(parseFloat(response.data.rate))
                ? null
                //@ts-ignore
                : parseFloat(response.data.rate),
            //@ts-ignore
            ask: isNaN(parseFloat(response.data.rate))
                ? null
                //@ts-ignore
                : parseFloat(response.data.rate),

        };
        let contraCurrency: string = "-";
        let selectingCurrencyType: FX_TRADE_CURRENCY_TYPE =
            FX_TRADE_CURRENCY_TYPE.SOURCE;
        //@ts-ignore
        if (fx.currencies[0] === response.data.dealtCurr) {
            contraCurrency = fx.currencies[1] === "" ? "-" : fx.currencies[1];
            //@ts-ignore
        } else if (fx.currencies[1] === response.data.dealtCurr) {
            contraCurrency = fx.currencies[0] === "" ? "-" : fx.currencies[0];
            selectingCurrencyType = FX_TRADE_CURRENCY_TYPE.TARGET;
        }

        const statusCodeMapper = (responseCode:string):any =>{
            if(responseCode === FX_ORDER_CONFIRM_STATUS_CODE.SUCCESS){
                return FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.SUCCESS
            }else if(responseCode === FX_ORDER_CONFIRM_STATUS_CODE.SUCCESS_BUT_ET_GATEWAY_FAIL){
                return FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.MBB_DRCR_SUCCESS_ARTS_UNSUCCESSFUL_OR_NO_RESPONSE
            }
        }

        return {
            transactionStatus:
            FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.SUCCESS,
            transactionRefNumber: "",
            orderId: response?.data?.orderId ?? "",
            transactionDate: moment
                .utc(response?.data?.tradeDate ?? "", "YYYYMMDDHHmmss")
                .local()
                .format("YYYY-MM-DD HH:mm:ss"),
            fromAccount: fxOrderForm.bankAccount.source.id,
            fromAccountName: fxOrderForm.bankAccount.source.label,
            toAccount: fxOrderForm.bankAccount.target.id,
            toAccountName: fxOrderForm.bankAccount.target.label,
            convertedFrom:
            //@ts-ignore
                fxOrderForm.source.currency === response.data.dealtCurr
                    //@ts-ignore
                    ? `${response.data.dealtCurr} ${formatNumber(
                        //@ts-ignore
                        response.data.amount,
                        DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
                    )}`
                    : `${contraCurrency} ${formatNumber(
                        // @ts-ignore 
                        response.data.contraAmount,
                        DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
                    )}`,
            amountReceived:
            //@ts-ignore
                fxOrderForm.target.currency === response.data.dealtCurr
                    //@ts-ignore
                    ? `${response.data.dealtCurr} ${formatNumber(
                        //@ts-ignore
                        response.data.amount,
                        DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
                    )}`
                    : `${contraCurrency} ${formatNumber(
                        // @ts-ignore
                        response.data.contraAmount,
                        DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
                    )}`,
            // exchangeRateOld: getRateByDealt(fxOrderForm.source.currency, fx)s
            //     ? `${fx.currencies[0]} 1 = ${fx.currencies[1]} ${getRateByDealt(
            //         fxOrderForm.source.currency,
            //         fx
            //     )}`
            //     : "-",
            exchangeRate: getRateByDealt(fxOrderForm.source.currency, fx)
                ? renderRate(fx.currencies[0], fx.currencies[1],getRateByDealt(fxOrderForm.source.currency,fx), getDpUqBySymbol(fx.symbol).dp, getDpUqBySymbol(fx.symbol).uq)
                : "-"
            ,
            transactionType: intl.formatMessage({
                id: "app.page.transactionReceipt.transactionType.fx.text",
            }),
            conversionFee: `${fxOrderForm.fee.currency} ${formatNumber(
                getConversionFee(),
                DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
            )}`,
            receiptTitle: intl.formatMessage({
                id: "app.page.transactionReceipt.receipt.title",
            }),
            statusDescription: intl.formatMessage({
                id: "app.page.transactionReceipt.statusDescription.success.text",
            }),
            statusCode:statusCodeMapper(response.responseCode)

        };
    } else if (
        response.responseCode === FX_ORDER_CONFIRM_STATUS_CODE.RSA_REQUIRED
    ) {
        // Required RSA case
        return {
            statusCode: FX_ORDER_CONFIRM_STATUS_CODE.RSA_REQUIRED.replaceAll(
                "P_",
                ""
            ),
            statusDescription: response.responseMsg ?? "",
            rsaQuestion: response?.data?.challengeQuestion ?? "",
        };
    } else if (
        response.responseCode === FX_ORDER_CONFIRM_STATUS_CODE.MBB_INSUFFICIENT_FUNDS ||
        response.responseCode === FX_ORDER_CONFIRM_STATUS_CODE.MBB_ACCOUNT_NOT_AUTHORIZED ||
        response.responseCode === FX_ORDER_CONFIRM_STATUS_CODE.RSA_LOCKED ||
        response.responseCode === FX_ORDER_CONFIRM_STATUS_CODE.RSA_DENIED ||
        response.responseCode === FX_ORDER_CONFIRM_STATUS_CODE.MBB_TIMEOUT ||
        response.responseCode === FX_ORDER_CONFIRM_STATUS_CODE.PAYMENT_API_TIMEOUT
    ) {
        return {
            statusDescription: intl.formatMessage({
                id: "app.page.transactionReceipt.statusDescription.fail.text",
            }),
            statusCode: responseCodeMapper(response.responseCode),
            transactionDate: moment
                .utc(response?.data?.tradeDate, "YYYYMMDDHHmmss")
                .local()
                .format("YYYY-MM-DD HH:mm:ss"),
            orderId: response?.data?.orderId ?? "",
        }
    } else {
        const responseCode = response.responseCode
        const statusCode = responseCode ? FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.WRONG_TAC_ENTERED : FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.FAIL
        return {
            transactionStatus: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL,
            statusDescription: intl.formatMessage({
                id: "app.page.transactionReceipt.statusDescription.fail.text",
            }),
            statusCode,
            failureMessage: JSON.stringify({
                referenceId: "",
                //@ts-ignore
                orderId: response.data.orderId,
                errorMsg: FX_ORDER_CONFIRM_STATUS_CODE.WRONG_TAC_ENTERED ? response?.data?.errorMsg : "",
                "date&time": moment
                    //@ts-ignore
                    .utc(response.data.tradeDate, "YYYYMMDDHHmmss")
                    .local()
                    .format("YYYY-MM-DD HH:mm:ss"),
            }),
        };
    }
};

export default formatConfirmFXOrder;

function formatTransactionDate(tradeDateString: string): string {
    return moment
        .utc(tradeDateString, "YYYYMMDDHHmmss")
        .local()
        .format("DD MMMM YYYY, hh:mmA");

    // Example usage:
    // let formattedDate = formatTransactionDate("20230129142145");
    // console.log(formattedDate);  // Outputs: 29 January 2023, 02:21PM
}

const responseCodeMapper =
    (responseCode: (FX_ORDER_CONFIRM_STATUS_CODE.MBB_INSUFFICIENT_FUNDS
        | FX_ORDER_CONFIRM_STATUS_CODE.MBB_ACCOUNT_NOT_AUTHORIZED
        | FX_ORDER_CONFIRM_STATUS_CODE.RSA_LOCKED
        | FX_ORDER_CONFIRM_STATUS_CODE.RSA_DENIED
        | FX_ORDER_CONFIRM_STATUS_CODE.MBB_TIMEOUT
        | FX_ORDER_CONFIRM_STATUS_CODE.PAYMENT_API_TIMEOUT
        )): string => {
        switch (responseCode) {
            case FX_ORDER_CONFIRM_STATUS_CODE.MBB_INSUFFICIENT_FUNDS:
                return FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.CHECK_YOUR_ACCOUNT_BALANCE
            case FX_ORDER_CONFIRM_STATUS_CODE.MBB_ACCOUNT_NOT_AUTHORIZED:
                return FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.INSUFFCIENT_BALANCE
            case FX_ORDER_CONFIRM_STATUS_CODE.RSA_LOCKED:
                return FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.RSA_LOCKED
            case FX_ORDER_CONFIRM_STATUS_CODE.RSA_DENIED:
                return FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.RSA_DENIED
            case FX_ORDER_CONFIRM_STATUS_CODE.MBB_TIMEOUT:
                return FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.GATEWAY_CONNECTION_TIMEOUT
            case FX_ORDER_CONFIRM_STATUS_CODE.PAYMENT_API_TIMEOUT:
                return FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.PAYMENT_API_TIMEOUT
        }
    }


