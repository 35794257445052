import {
  FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE,
  FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE,
  DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT,
} from "../../../../../constants/fx.constant";
import { getIntlObject } from "../../../../../utils/intl";
import { FXOrderFormType, FXType } from "../../../../../types/fx.type";
import { getDpUqBySymbol } from "../../../../../components/fx/service/getDpUqBySymbol";
import { renderRate } from "../../../../../components/order/CalculationCard";
import { getConversionFee } from "../../../order";
import { formatNumber } from "../../../../../utils/common.utils";
import formatFXRawList from "../formatFXRawList";
import getRateByDealt from "../../../getRateByDealt";

function formatDecimal(
  inputNumber: string | number,
  numberOfDecimals: number
): string {
  var number =
    typeof inputNumber === "string" ? parseFloat(inputNumber) : inputNumber;
  if (isNaN(number)) {
    return "Invalid number";
  }
  if (number > 0 && number % 1 !== 0) {
    if (number.toString().split(".")[1].length > numberOfDecimals) {
      number = Number.parseFloat(number + "1");
    }
  }
  return number.toFixed(numberOfDecimals);
}

function formatDateWithFixedTime(dateString: string): string {
  const date = new Date(dateString);

  // Extract date components
  const yyyy = date.getFullYear().toString();
  const MM = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
  const dd = date.getDate().toString().padStart(2, "0");

  // Format the date
  return `${yyyy}-${MM}-${dd} ${23}:${59}:${59}`;
}

export const formatS2UMOFailOrder = (referenceId: string, orderId: string) => {
  const intl = getIntlObject();
  return {
    transactionStatus: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL,
    statusDescription: intl.formatMessage({
      id: "app.page.transactionReceipt.statusDescription.fail.text",
    }),
    statusCode: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.FAIL,
    failureMessage: JSON.stringify({
      referenceId: referenceId,
      orderId: orderId,
    }),
  };
};

export const formatS2UFailedInitOrder = (orderId: string) => {
  const intl = getIntlObject();
  return {
    transactionStatus: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL,
    statusDescription: intl.formatMessage({
      id: "app.page.transactionReceipt.statusDescription.fail.text",
    }),
    statusCode: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.FAIL,
    failureMessage: JSON.stringify({
      orderId: orderId,
    }),
  };
};

export const formatS2UExpiredOrder = (
  orderId: string,
  transactionType: string,
  fxOrderForm: FXOrderFormType,
  response: any
) => {
  const intl = getIntlObject();
  if (fxOrderForm.future) {
    return {
      transactionStatus: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL,
      statusDescription: intl.formatMessage({
        id: "app.page.transactionReceipt.statusDescription.authorisationFailed.text",
      }),
      statusCode: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.S2U_EXPIRED,
      fromAccount: fxOrderForm.bankAccount.source!.id,
      fromAccountName: fxOrderForm.bankAccount.source!.label,
      toAccount: fxOrderForm.bankAccount.target!.id,
      toAccountName: fxOrderForm.bankAccount.target!.label,
      convertedFrom: `${fxOrderForm.source.currency} ${formatDecimal(
        fxOrderForm.source.amount.value,
        DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
      )}`,
      amountReceived: `${fxOrderForm.target.currency} ${formatDecimal(
        fxOrderForm.target.amount.value,
        DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
      )}`,
      targetRate: renderRate(
        fxOrderForm.fx!.currencies[0],
        fxOrderForm.fx!.currencies[1],
        fxOrderForm.futureTargetRate!,
        getDpUqBySymbol(fxOrderForm.fx?.symbol!).dp,
        getDpUqBySymbol(fxOrderForm.fx?.symbol!).uq
      ),
      transactionType: transactionType,
      conversionFee: `${fxOrderForm.fee.currency} ${formatNumber(
        getConversionFee(),
        DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
      )}`,
      expiryDate: formatDateWithFixedTime(fxOrderForm.expiryDate!),
      failureMessage: JSON.stringify({
        transactionType: transactionType,
        subMessage: intl.formatMessage({
          id: "app.page.transactionReceipt.subMessage.expired.text",
        }),
      }),
    };
  } else {
    const fx: FXType = {
      ...formatFXRawList([response.data.symbol])[0],
      bid: isNaN(parseFloat(response.data.rate))
        ? null
        : parseFloat(response.data.rate),
      ask: isNaN(parseFloat(response.data.rate))
        ? null
        : parseFloat(response.data.rate),
    };
    let contraCurrency: string = "-";
    if (fx.currencies[0] === response.data.dealtCurr) {
      contraCurrency = fx.currencies[1] === "" ? "-" : fx.currencies[1];
    } else if (fx.currencies[1] === response.data.dealtCurr) {
      contraCurrency = fx.currencies[0] === "" ? "-" : fx.currencies[0];
    }
    return {
      transactionStatus: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL,
      orderId: orderId,
      fromAccount: fxOrderForm?.bankAccount?.source?.id ?? "",
      fromAccountName: fxOrderForm?.bankAccount?.source?.label ?? "",
      toAccount: fxOrderForm?.bankAccount?.target?.id ?? "",
      toAccountName: fxOrderForm?.bankAccount?.target?.label ?? "",
      convertedFrom:
        fxOrderForm.source.currency === response.data.dealtCurr
          ? `${response.data.dealtCurr} ${formatNumber(
              response.data.amount,
              DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
            )}`
          : `${contraCurrency} ${formatNumber(
              response.data.contraAmount,
              DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
            )}`,
      amountReceived:
        fxOrderForm.target.currency === response.data.dealtCurr
          ? `${response.data.dealtCurr} ${formatNumber(
              response.data.amount,
              DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
            )}`
          : `${contraCurrency} ${formatNumber(
              response.data.contraAmount,
              DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
            )}`,
      exchangeRate: getRateByDealt(fxOrderForm.source.currency, fx)
        ? renderRate(
            fx.currencies[0],
            fx.currencies[1],
            getRateByDealt(fxOrderForm.source.currency, fx),
            getDpUqBySymbol(fx.symbol).dp,
            getDpUqBySymbol(fx.symbol).uq
          )
        : "-",
      transactionType: transactionType,
      conversionFee: `${fxOrderForm.fee.currency} ${formatNumber(
        getConversionFee(),
        DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
      )}`,
      statusDescription: intl.formatMessage({
        id: "app.page.transactionReceipt.statusDescription.authorisationFailed.text",
      }),
      statusCode: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.S2U_EXPIRED,
      failureMessage: JSON.stringify({
        transactionType: transactionType,
        subMessage: intl.formatMessage({
          id: "app.page.transactionReceipt.subMessage.expired.text",
        }),
        orderId: orderId,
      }),
    };
  }
};

export const formatS2URejectedOrder = (
  orderId: string,
  transactionType: string,
  fxOrderForm: FXOrderFormType,
  response: any
) => {
  const intl = getIntlObject();
  if (fxOrderForm.future) {
    return {
      transactionStatus: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL,
      statusDescription: intl.formatMessage({
        id: "app.page.transactionReceipt.statusDescription.authorisationFailed.text",
      }),
      statusCode: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.S2U_REJECTED,
      fromAccount: fxOrderForm.bankAccount.source!.id,
      fromAccountName: fxOrderForm.bankAccount.source!.label,
      toAccount: fxOrderForm.bankAccount.target!.id,
      toAccountName: fxOrderForm.bankAccount.target!.label,
      convertedFrom: `${fxOrderForm.source.currency} ${formatDecimal(
        fxOrderForm.source.amount.value,
        DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
      )}`,
      amountReceived: `${fxOrderForm.target.currency} ${formatDecimal(
        fxOrderForm.target.amount.value,
        DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
      )}`,
      targetRate: renderRate(
        fxOrderForm.fx!.currencies[0],
        fxOrderForm.fx!.currencies[1],
        fxOrderForm.futureTargetRate!,
        getDpUqBySymbol(fxOrderForm.fx?.symbol!).dp,
        getDpUqBySymbol(fxOrderForm.fx?.symbol!).uq
      ),
      transactionType: transactionType,
      conversionFee: `${fxOrderForm.fee.currency} ${formatNumber(
        getConversionFee(),
        DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
      )}`,
      expiryDate: formatDateWithFixedTime(fxOrderForm.expiryDate!),
      failureMessage: JSON.stringify({
        transactionType: transactionType,
        subMessage: intl.formatMessage({
          id: "app.page.transactionReceipt.subMessage.rejected.text",
        }),
      }),
    };
  } else {
    const fx: FXType = {
      ...formatFXRawList([response.data.symbol])[0],
      bid: isNaN(parseFloat(response.data.rate))
        ? null
        : parseFloat(response.data.rate),
      ask: isNaN(parseFloat(response.data.rate))
        ? null
        : parseFloat(response.data.rate),
    };
    let contraCurrency: string = "-";
    if (fx.currencies[0] === response.data.dealtCurr) {
      contraCurrency = fx.currencies[1] === "" ? "-" : fx.currencies[1];
    } else if (fx.currencies[1] === response.data.dealtCurr) {
      contraCurrency = fx.currencies[0] === "" ? "-" : fx.currencies[0];
    }
    return {
      transactionStatus: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL,
      orderId: orderId,
      fromAccount: fxOrderForm?.bankAccount?.source?.id ?? "",
      fromAccountName: fxOrderForm?.bankAccount?.source?.label ?? "",
      toAccount: fxOrderForm?.bankAccount?.target?.id ?? "",
      toAccountName: fxOrderForm?.bankAccount?.target?.label ?? "",
      convertedFrom:
        fxOrderForm.source.currency === response.data.dealtCurr
          ? `${response.data.dealtCurr} ${formatNumber(
              response.data.amount,
              DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
            )}`
          : `${contraCurrency} ${formatNumber(
              response.data.contraAmount,
              DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
            )}`,
      amountReceived:
        fxOrderForm.target.currency === response.data.dealtCurr
          ? `${response.data.dealtCurr} ${formatNumber(
              response.data.amount,
              DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
            )}`
          : `${contraCurrency} ${formatNumber(
              response.data.contraAmount,
              DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
            )}`,
      exchangeRate: getRateByDealt(fxOrderForm.source.currency, fx)
        ? renderRate(
            fx.currencies[0],
            fx.currencies[1],
            getRateByDealt(fxOrderForm.source.currency, fx),
            getDpUqBySymbol(fx.symbol).dp,
            getDpUqBySymbol(fx.symbol).uq
          )
        : "-",
      transactionType: transactionType,
      conversionFee: `${fxOrderForm.fee.currency} ${formatNumber(
        getConversionFee(),
        DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
      )}`,
      statusDescription: intl.formatMessage({
        id: "app.page.transactionReceipt.statusDescription.authorisationFailed.text",
      }),
      statusCode: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.S2U_REJECTED,
      failureMessage: JSON.stringify({
        transactionType: transactionType,
        subMessage: intl.formatMessage({
          id: "app.page.transactionReceipt.subMessage.rejected.text",
        }),
        orderId: orderId,
      }),
    };
  }
};
