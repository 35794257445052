import {getConversionFee} from "../formatters/fx/order";
import {FXRateAlertType} from "../types/fx.type";

export const FX_STRING_LENGTH = 6;
export const FX_CURRENCY_PAIR_AMOUNT = 2;

export const DEFAULT_FX_PRICE_DECIMAL_POINT = 4;

export const DEFAULT_FX_PRICE_UNIT_QUOTE = 9

export const COUNTRY_CURRENCY_MAP = {
    ALL: "ALB", //  Albanian lek
    DZD: "DZA", //  Algerian dinar
    ADP: "", //  Andorran peseta
    AON: "", //  Angolan new kwanza
    ARS: "", //  Argentine peso
    AUD: "AUS", //  Australian dollar
    AUc: "AUS", //  Australian cent (deprecated)
    ATS: "", //  Austrian schilling
    BSD: "", //  Bahamian dollar
    BHD: "", //  Bahraini dinar
    BDT: "BGD", //  Bangladesh taka
    AMD: "ARM", //  Armenian Dram
    BBD: "", //  Barbados dollar
    BEF: "", //  Belgian franc
    BMD: "", //  Bermudian dollar
    BTN: "", //  Bhutan ngultrum
    BOB: "", //  Bolivian boliviano
    BAD: "", //  Bosnia and Herzegovina Dinar
    BWP: "", //  Botswanian pula
    BRL: "", //  Brazilian real
    BZD: "", //  Belize dollar
    SBD: "", //  Solomon Islands dollar
    BND: "BRU", //  Brunei dollar
    BGL: "", //  Bulgarian Lev (pre 5 July 1999)
    MMK: "", //  Myanmar kyat
    BIF: "", //  Burundi franc
    KHR: "", //  Kampuchean riel
    CAD: "CAN", //  Canadian dollar
    CVE: "", //  Cape Verde escudo
    KYD: "", //  Cayman Islands dollar
    LKR: "", //  Sri Lanka rupee
    CLP: "", //  Chilean peso
    CNY: "CHN", //  Chinese yuan renminbi
    COP: "", //  Colombian peso
    KMF: "", //  Comoro franc
    ZRN: "", //  Zaire new zaire
    CRC: "", //  Costa Rican colon
    HRK: "", //  Croatian Kuna
    CUP: "", //  Cuban peso
    CYP: "", //  Cyprus pound
    "200": "", //  Not allocated
    CZK: "", //  Czech koruna
    DKK: "DNK", //  Danish krone
    DOP: "", //  Dominican peso
    ECS: "", //  Ecuador sucre
    SVC: "", //  El Salvador colon
    EEK: "", //  Estonian kroon
    ETB: "", //  Ethiopian birr
    ERN: "", //  Nakfa
    FKP: "", //  Falkland Islands pound
    FJD: "", //  Fiji dollar
    FIM: "", //  Finnish markka
    FRF: "", //  French franc
    DJF: "", //  Djibouti franc
    GMD: "", //  Gambian dalasi
    DEM: "", //  Deutsche Mark
    GHC: "", //  Ghanaian cedi
    GIP: "", //  Gibraltar pound
    GRD: "", //  Greek drachma
    GTQ: "", //  Guatemalan quetzal
    GNF: "", //  Guinea franc
    GYD: "", //  Guyana dollar
    HTG: "", //  Haitian gourde
    HNL: "", //  Honduran lempira
    HKD: "HKG", //  Hong Kong dollar
    HKc: "", //  Hong Kong cent (deprecated)
    HUF: "", //  Hungarian forint
    ISK: "", //  Iceland krona
    INR: "", //  Indian rupee
    IDR: "IDN", //  Indonesian rupiah
    IRR: "", //  Iranian rial
    IQD: "", //  Iraqi dinar
    IEP: "", //  Irish pound
    IEp: "", //  Irish pence (deprecated)
    ILS: "", //  Israeli shekel
    ITL: "", //  Italian lira
    JMD: "", //  Jamaican dollar
    JPY: "JPN", //  Japanese yen
    KZT: "", //  Kazakhstan Tenge
    JOD: "", //  Jordanian dinar
    KES: "", //  Kenyan shilling
    KPW: "", //  North Korean won
    KRW: "", //  Republic of Korea won
    KWD: "", //  Kuwaiti dinar
    KGS: "", //  Kyrgyzstan Som
    LAK: "", //  Lao kip
    LBP: "", //  Lebanese pound
    LSL: "", //  Lesotho loti
    LVL: "", //  Latvian Lats
    LRD: "", //  Liberian dollar
    LYD: "", //  Libyan dinar
    LTL: "", //  Lithuanian litas
    LUF: "", //  Luxembourg franc
    MOP: "", //  Macau pataca
    "450": "", //  Not allocated (was Malagasy franc)
    MWK: "", //  Malawi kwacha
    MYR: "MYS", //  Malaysian ringgit
    MYc: "MYS", //  Malaysian cent (deprecated)
    MVR: "", //  Maldivian rufiyaa
    MLF: "", //  Mali franc
    MTL: "", //  Maltese lira
    MRO: "", //  Mauritanian ouguiya
    MUR: "", //  Mauritius rupee
    MXN: "", //  Mexican peso
    MNT: "", //  Mongolian tugrik
    MDL: "", //  Moldovan leu
    MAD: "", //  Moroccan dirham
    MZM: "", //  Mozambique metical
    OMR: "", //  Rial Omani
    NAD: "", //  Namibia dollar
    NPR: "", //  Nepalese rupee
    NLG: "", //  Netherlands guilder
    ANG: "", //  Netherlands Antillean guilder
    AWG: "", //  Aruban florin
    VUV: "", //  Vanuatu vatu
    NZD: "NZL", //  New Zealand dollar
    NIO: "", //  Nicaraguan cordoba oro
    NGN: "", //  Nigerian naira
    NOK: "NOR", //  Norwegian krone
    PKR: "", //  Pakistan rupee
    PAB: "", //  Panamanian balboa
    PGK: "", //  Papua New Guinea kina
    PYG: "", //  Paraguay guarani
    PEN: "", //  Peruvian nuevo sol
    PHP: "PHL", //  Philippine peso
    PLZ: "", //  Old Polish zloty
    PTE: "", //  Portuguese escudo
    GWP: "", //  Guinea-Bissau peso
    TPE: "", //  Timor escudo
    QAR: "QAT", //  Qatari rial
    ROL: "", //  Romanian leu
    RUB: "", //  Russian Ruble
    RWF: "", //  Rwanda franc
    SHP: "", //  Saint Helena pound
    STD: "", //  Sao Tome and Principe dobra
    SAR: "SAU", //  Saudi Arabian riyal
    SCR: "", //  Seychelles rupee
    SLL: "", //  Sierra Leone leone
    SGD: "SGP", //  Singapore dollar
    SKK: "", //  Slovak Koruna
    VND: "", //  Vietnamese dong
    SIT: "", //  Slovenian tolar
    SOS: "", //  Somali shilling
    ZAR: "", //  South African rand
    ZWD: "", //  Zimbabwe dollar
    "720": "", //  Not allocated
    ESP: "", //  Spanish peseta
    SSP: "", //  South Sudanese Pound
    SDD: "", //  Sudanese dinar
    SRG: "", //  Suriname guilder
    SZL: "", //  Swaziland lilangeni
    SEK: "SWE", //  Swedish krona
    CHF: "CHE", //  Swiss franc
    SYP: "", //  Syrian pound
    THB: "THA", //  Thai baht
    TOP: "", //  Tongan pa'anga
    TTD: "", //  Trinidad and Tobago dollar
    AED: "ARE", //  UAE dirham
    TND: "", //  Tunisian dinar
    TRL: "", //  Old Turkish lira
    TMM: "", //  Turkmenistan manat
    UGX: "", //  Uganda shilling
    UAK: "", //  Ukrainian karbovanet
    MKD: "", //  North Macedonian Denar
    EGP: "", //  Egyptian pound
    GBP: "GBR", //  UK pound sterling
    GBp: "GBR", //  UK pence sterling (deprecated)
    TZS: "", //  Tanzanian shilling
    USD: "USA", //  US dollar
    USc: "USA", //  US cents (deprecated)
    UYU: "", //  Peso Uruguayo
    UZS: "", //  Uzbekistan Sum
    VEB: "", //  Old Venezuelan bolivar
    WST: "", //  Samoan tala
    YER: "", //  Yemeni rial
    "890": "", //  Not allocated
    CSD: "", //  Serbian Dinar
    ZMK: "", //  Zambian kwacha
    ROb: "", //  Romanian bani
    TWD: "", //  New Taiwan dollar
    BHf: "", //  Bahraini Fils
    KWf: "", //  Kuwaiti Fils
    OMb: "", //  Baiza Omani
    SAh: "", //  Saudi Arabian Halalah
    QAd: "", //  Qatari Dirham
    AEf: "", //  UAE Fils
    UYW: "", //  Uruguayan Unidad Previsional
    VES: "", //  Venezuelan Bolivar Soberano
    MRU: "", //  Mauritanian Ouguiya
    STN: "", //  Sao Tome and Principe Dobra
    CUC: "", //  Cuba Convertible Peso
    ZWL: "", //  Zimbabwe Dollar
    BYN: "", //  Belarusian Ruble
    TMT: "", //  Turkmenistan New Manat
    GHS: "", //  Ghana Cedi
    VEF: "", //  Venezuelan Bolivar Fuerte
    SDG: "", //  SUDANESE POUND
    UYI: "", //  Uruguay Peso en Unidades Indexadas
    RSD: "", //  Serbian Dinar
    ZWN: "", //  'new' Zimbabwe Dollar
    MZN: "", //  Mozambique Metical
    AZN: "", //  Azerbaijanian Manat
    RON: "", //  New Romanian Leu
    CHE: "", //  WIR Euro
    CHW: "", //  Swiss WIR Franc
    TRY: "", //  Turkish lira
    XAF: "", //  CFA franc BEAC
    XCD: "", //  East Caribbean dollar
    XOF: "", //  CFA franc BCEAO
    XPF: "", //  CFP franc
    XEU: "", //  European currency unit
    XBA: "", //  European composite unit
    XBB: "", //  European monetary unit
    XBC: "", //  European unit of Account 9
    XBD: "", //  European unit of Account 17
    XAU: "", //  Gold
    XDR: "", //  Special drawing right
    XAG: "", //  Silver
    XPT: "", //  Platinum
    XTS: "", //  Reserved for testing
    XPD: "", //  Palladium
    XUA: "", //  ADB Unit of Account
    ZMW: "", //  Zambian kwacha
    SRD: "", //  Surinam Dollar
    MGA: "", //  Malagasy Ariary
    COU: "", //  Colombian Unidad de Valor Real
    AFN: "", //  Afghanistan afghani
    TJS: "", //  Tajikistan Somoni
    AOA: "", //  Angolan kwanza
    BYR: "", //  Belarusian Ruble (Jan 2000 - Dec 2016)
    BGN: "", //  Bulgarian Lev
    CDF: "", //  Congolese Franc
    BAM: "", //  Convertible Mark
    EUR: "EEE", //  Euro
    MXV: "", //  Mexico Unidad de Inversion
    UAH: "", //  Ukrainian Hryvnia
    GEL: "", //  Georgian Lari
    BOV: "", //  Bolivian Mvdol
    PLN: "", //  New Polish zloty
    CLF: "", //  Chilean unidad de fomento
    ZAL: "", //  South African financial rand
    XSU: "", //  Sucre
    ESB: "", //  Spanish peseta (accounts 'B')
    ESA: "", //  Spanish peseta (accounts 'A')
    USN: "", //  US dollar (next day)
    USS: "", //  US dollar (same day)
    XXX: "", //  Code assigned for no currency transactions
    tst: "", //  Test
    CAc: "", //  Canadian cents ****
    NZc: "NZL", //  New Zealand cents ****
    SGc: "SGP", //  Singapore cents ****
    ZAc: "", //  South African cents ****
    ZWc: "", //  Zimbabwe cents
    EUc: "", //  The Euro minor currency, the cent
    NAc: "", //  Namibian cent
    ILa: "", //  Israeli Agora
    HRL: "", //  Croatian Lipa (was Croatian Kuna)
    BWt: "", //  Botswana Thebe
    ZZZ: "", //  Multi-Currency
    TRk: "", //  New Turkish Kurus
    MWt: "", //  Malawi Tambala
    BRc: "", //  Brazilian Centavos
    CNH: "", //  Chinese Yuan Offshore
    UST: "", //  Thousands of US dollars
    XVN: "", //  Ven
    CRU: "", //  Costa Rican Unidad de Desarrolla
    BTC: "", //  Bitcoin
    UYR: "", //  Uruguayan Peso en Unidades Reajustables
    BYk: "", //  Belarusian Kapeyka
    BCH: "", //  Bitcoin Cash
    ETH: "", //  Ethereum
    XRP: "", //  Ripple
    NTK: "", //  Neurotoken
    LTC: "", //  Litecoin
    BOF: "", //  Bolivian Unidad de Fomento de Vivienda
    VEc: "", //  Venezuelan Centimo
    ZZ9: "", //  Cryptocurrency
};

export const CURRENCY_KEYWORD = {
    AED: ["United Arab Emirates", "UAE", "Dirham", "DH"],
    AUD: ["Australia", "Aussie"],
    BDT: ["Bangladesh", "Taka"],
    BND: ["Brunei", "B$"],
    CAD: ["Canada", "Candy"],
    CHF: ["Swiss", "Franc", "Switzerland", "FR", "SFR"],
    CNY: ["Chinese", "Yuan", "Renminbi", "RMB"],
    DKK: ["Denmark", "Danish Krone", "KR"],
    EUR: ["Euro", "€"],
    GBP: ["Pound", "Sterling", "British", "UK", "United Kingdom", "£"],
    HKD: ["Hong Kong"],
    IDR: ["Indonesia", "Rupiah"],
    JPY: ["Japan", "Yen", "¥"],
    MYR: ["Malaysia", "Ringgit", "RM"],
    NOK: ["Norway Krone"],
    NZD: ["New Zealand", "Kiwi"],
    PHP: ["Philippines", "Peso"],
    QAR: ["Qatari", "Qatar Riyal", "QR"],
    SAR: ["Saudi Riyal"],
    SEK: ["Swedish", "Krona", "Crown"],
    SGD: ["Singapore", "S$"],
    THB: ["Thai", "Baht"],
    USD: ["United States", "America", "Dollar", "$"],
};

export const FX_SEARCH_IGNORE_SPECIAL_CHARACTER_REGEX = /([ \/])/g;

export const MAX_FX_TRADE_AMOUNT = 999999999.99;

export enum FX_TRADE_CURRENCY_TYPE {
    SOURCE = "source",
    TARGET = "target",
}

export enum FX_ORDER_FORM_AMOUNT_VALIDATION_TYPE {
    ALL,
    MIN,
    MAX,
}

export enum FX_MARKET_ORDER_FORM_STATE {
    FX,
    ACCOUNT,
    ORDER_CREATED,
    ORDER_ACCEPTED,
}

export enum FX_FUTURE_ORDER_FORM_STATE {
    FX,
    ACCOUNT,
    ORDER_CREATED,
}

export enum FX_ORDER_CONFIRM_STATUS_CODE {
    SUCCESS = "0",
    SUCCESS_BUT_ET_GATEWAY_FAIL = "ARTS0201",
    RSA_REQUIRED = "P_0001",
    RSA_LOCKED = "P_0002",
    RSA_DENIED = "P_0003",
    MBB_TIMEOUT = "P_0004",
    MBB_INSUFFICIENT_FUNDS = "P_0051",
    MBB_ACCOUNT_NOT_AUTHORIZED = "P_0076",
    PAYMENT_API_TIMEOUT = "P_9408",
    MBB_INTERNAL_ERROR = "P_9999",
    WRONG_TAC_ENTERED = "00A5"
}

export enum FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE {
    SUCCESS = "Successful",
    FAIL = "Unsuccessful",
    TIMEOUT = "Timeout",
}

export enum FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE {
    SUCCESS = "0000",
    FAIL = "9999",
    CHECK_YOUR_ACCOUNT_BALANCE = "0051",
    INSUFFCIENT_BALANCE = "0076",
    MBB_DRCR_SUCCESS_ARTS_UNSUCCESSFUL_OR_NO_RESPONSE = "0201",
    PAYMENT_API_TIMEOUT = "9408",
    GATEWAY_CONNECTION_TIMEOUT = "0004",
    RSA_LOCKED = "0002",
    RSA_DENIED = "0003",
    WRONG_TAC_ENTERED = "00A5",
    S2U_REJECTED = "MS3000",
    S2U_EXPIRED = "MS3022"
}

export const DEFAULT_FX_ORDER_TIMEOUT = 5000; // ms, default 1m50s

export const DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT = 2;
export const DEFAULT_FX_CALCULATOR_FORM_FIELD = {
    source: {
        currency: "MYR",
        amount: {
            input: "",
            value: 0,
        },
        name: "Malaysian Ringgit",
    },
    target: {
        amount: {
            input: "",
            value: 0,
        },
        name: "",
    },
    selectingCurrencyType: FX_TRADE_CURRENCY_TYPE.SOURCE,
};


export const DEFAULT_FX_RATE_ALERT_FIELD:FXRateAlertType = {
    source: {
        currency: "",
        name: "",
    },
    target: {
        currency: "",
        name: "",
    },
    expiryDate: "",
    futureTargetRate:"",
    futureTargetRateString:"",
}

export const DEFAULT_FX_ORDER_FORM_FIELD = {
    source: {
        currency: "MYR",
        amount: {
            input: "",
            value: 0,
        },
    },
    target: {
        amount: {
            input: "",
            value: 0,
        },
    },
    fee: {
        conversion: getConversionFee(),
    },
    selectingCurrencyType: FX_TRADE_CURRENCY_TYPE.SOURCE,
    future: false,
    bankAccount: {
        source: undefined,
        target: undefined,
    },
    futureTargetRate:"",
    futureTargetRateString:"",
};


export const ONE_SECOND = 1000
const TIMEOUT_FOR_TESTING = 8
export const POST_MESSAGE_PASSWORD_WAITING_TIME = process.env.REACT_APP_USE_TIMEOUT_FOR_TESTING === "true" ?
    ONE_SECOND * TIMEOUT_FOR_TESTING :
    ONE_SECOND * 120; // default 2m, as specified in MBB LiveFX - List of EXceptions 20240119
export const POST_MESSAGE_RSA_WAITING_TIME = process.env.REACT_APP_USE_TIMEOUT_FOR_TESTING === "true" ?
    ONE_SECOND * TIMEOUT_FOR_TESTING :
    ONE_SECOND * 120; // default 2m, as specified in MBB LiveFX - List of EXceptions 20240119
export const POST_MESSAGE_ACKE_WAITING_TIME = process.env.REACT_APP_USE_TIMEOUT_FOR_TESTING === "true" ?
    ONE_SECOND * TIMEOUT_FOR_TESTING :
    ONE_SECOND * 120 // default 2m

export const SHOW_FUTURE_SESSION:boolean= true

export const DFFAULT_CURRENCY_FIELD = {
    currency: "",
    amount: {
        input: "",
        value: 0,
    },
    name: "",
};

export const SHOW_PAGE_RANGE: boolean = false
export const NUM_ITEMS_IN_ORDER_STATUS:number = 10
export const ORDER_STATUS_RATE_ALERT_SHOW_LAST_N_DAYS:number = 30 // here is 29, it means last 30 days, as 29 + 1 (today) is 30
export const MAX_NUM_RATE_ALERT = 10

export const AFTER_CREATE_RATE_ALERT_WAIT_RELOAD_SECOND_TIME = 2000
